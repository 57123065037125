






import { Component, Vue } from "vue-property-decorator";
import PromoCodeRequestTable from "./promoCodeRequest/PromoCodeRequestTable.vue";

@Component({ components: { PromoCodeRequestTable } })
export default class PromoCodeRequest extends Vue {}
