



























































































import { LoadingStatusType, TableSorting } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import {
  PromoCampaignModel,
  PromoCodeListRequestModel,
  PromoCodeModel,
  PromoRequestTableList,
} from "@/model/promoCode/promoCodeModel";
import { DataOptions, DataPagination } from "@/model/table/tableViewModel";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "@/service/authService";

const auth = new AuthService();
const StorePromoCode = namespace("PromoCode");

@Component({})
export default class PromoCodeRequestTable extends Vue {
  private LoadingStatusType = LoadingStatusType;
  private textSearchPromoCode = "";
  private textSearchPromoCampaign = "";
  private table: any = {
    header: [],
    detail: [],
  };
  private action: any = [{ actionValue: 1, text: "แก้ไข" }];
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;
  private countItem: any = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private isShowModalPromoCodeCreate = false;
  private permission = false;
  private isCanCreate = false;
  private tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };
  private footerProp = {
    "items-per-page-options": this.pageSizeList,
  };

  @StorePromoCode.Getter
  private getPromoRequestTableList!: Array<any>;
  @StorePromoCode.Getter
  private getPromoRequestTableListLoadingStatus!: LoadingStatusType;
  @StorePromoCode.Getter
  private getHeaderPromoRequestTable!: Array<any>;
  @StorePromoCode.Getter
  private getCountPromoRequestTableList!: number | null;
  @StorePromoCode.Action
  private DoGetPromoRequestTableList!: (input: PromoRequestTableList) => void;

  async created() {
    const input = {
      pageNo: this.tableOptions.page,
      pageSize: this.tableOptions.itemsPerPage,
      tableSorting: TableSorting.DESC,
    };
    this.DoGetPromoRequestTableList(input);
    const accessPermission = await auth.getPermissions();
    this.isCanCreate = accessPermission["promoCode"]["create"];
    document.title = "Promo Code Table";
  }

  beforeDestroy() {
    document.title = "peakadminexprience";
  }

  @Watch("getPromoRequestTableListLoadingStatus")
  getPromoCodeListLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.header = NewObject(this.getHeaderPromoRequestTable);
      this.table.detail = this.getPromoRequestTableList.length > 0 ? NewObject(this.getPromoRequestTableList) : [];
      this.countItem = this.getCountPromoRequestTableList;
      this.totalPage = Math.ceil(this.countItem / this.pageSize);
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  // @Watch("pageNo")
  // pageNoChanged(newVal: number) {
  //   const input = {
  //     pageNo: newVal,
  //     pageSize: this.pageSize,
  //     tableSorting: TableSorting.DESC,
  //   };
  //   this.DoGetPromoRequestTableList(input);
  // }

  // @Watch("pageSize")
  // pageSizeChanged(newVal: number) {
  //   const input = {
  //     pageNo: this.pageNo,
  //     pageSize: newVal,
  //     tableSorting: TableSorting.DESC,
  //   };
  //   this.DoGetPromoRequestTableList(input);
  // }

  @Watch("tableOptions", { deep: true })
  tableOptionsChanged(newVal: DataOptions, oldVal: DataOptions) {
    const isPageChange = newVal.page != oldVal.page;
    const isPageSizeChange = newVal.itemsPerPage != oldVal.itemsPerPage;
    if (isPageChange || isPageSizeChange) {
      const input = {
        pageNo: this.tableOptions.page,
        pageSize: this.tableOptions.itemsPerPage,
        tableSorting: TableSorting.DESC,
      };
      this.DoGetPromoRequestTableList(input);
    } else if (this.tableOptions.sortBy?.length === 1 && this.tableOptions.sortDesc?.length === 1) {
      const { sortBy, sortDesc } = this.tableOptions;
      this.table.detail = this.table.detail.sort((a: any, b: any) => {
        const sortA = a[sortBy[0]];
        const sortB = b[sortBy[0]];

        if (sortDesc[0]) {
          if (sortA < sortB) return 1;
          if (sortA > sortB) return -1;
          return 0;
        } else {
          if (sortA < sortB) return -1;
          if (sortA > sortB) return 1;
          return 0;
        }
      });
    }
  }

  private GetAsanaTaskNumberText(taskText: string) {
    if (isNaN(Number(taskText)) || !taskText) {
      return "-";
    } else {
      return taskText;
    }
  }

  public RedirectToPromoCodeRequestCreate() {
    this.$router.push({
      name: "promoCodeRequestCreate",
    });
  }

  public RedirectToRequestDetailPage(requestId: any) {
    const route = this.$router.resolve({
      name: "promoCodeRequestDetail",
      query: {
        requestId: String(requestId),
      },
    });
    window.open(route.href, "_blank");
  }
}
